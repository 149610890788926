var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"display":"flex","flex-direction":"column"},attrs:{"elevation":"24","max-width":_vm.maxWidth,"min-width":_vm.minWidth}},[_c('v-carousel',{attrs:{"continuous":true,"cycle":false,"show-arrows":false,"hide-delimiter-background":"","delimiter-icon":"mdi-minus","hide-delimiters":_vm.work.hasSingleImage(),"height":"300"}},_vm._l((_vm.work.image),function(image,index){return _c('v-carousel-item',{key:index},[_c('router-link',{attrs:{"to":'/shill/'+_vm.work.id+'/'+_vm.work.name.replace(/ /g, '+')}},[_c('v-img',{attrs:{"src":image,"gradient":_vm.work.cardGradient(),"height":"300"}},[_c('v-card-title',{staticStyle:{"text-shadow":"0 0 2px #000, 0 0 3px #000, 0 0 4px #000"},attrs:{"lights-out":""}},[_vm._v(_vm._s(_vm.work.name))]),_c('v-card-subtitle',{staticStyle:{"color":"#fff","text-shadow":"0 0 1px #000, 0 0 2px #000"}},[_vm._v(_vm._s(_vm.work.author))])],1)],1)],1)}),1),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","flex-grow":"1"}},[_c('v-card-text',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.work.description)}})]),_c('v-card-actions',{staticStyle:{"align-items":"end","display":"flex","flex-grow":"1"}},[_c('v-container',{staticClass:"mb-0 pl-1 pb-1 align-self-end"},[_c('v-slide-group',{attrs:{"multiple":""}},_vm._l((_vm.work.tags),function(tag){return _c('v-slide-item',{key:tag},[_c('v-chip',{staticClass:"mr-1",on:{"click":function($event){return _vm.onClickTag(tag)}}},[_vm._v(_vm._s(tag))])],1)}),1),_c('v-list-item',{staticClass:"grow"},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(!_vm.isAuthenticated)?_c('v-icon',{staticClass:"mr-1",on:{"click":function($event){return _vm.onLike(_vm.work.id)}}},[_vm._v(" mdi-heart-outline ")]):(_vm.isAuthenticated && !_vm.localLiked)?_c('v-icon',{staticClass:"mr-1",on:{"click":function($event){return _vm.onLike(_vm.work.id)}}},[_vm._v(" mdi-heart-outline ")]):_c('v-icon',{staticClass:"mr-1",on:{"click":function($event){return _vm.onLike(_vm.work.id)}}},[_vm._v(" mdi-heart ")]),_c('span',{staticClass:"subheading mr-2"},[_vm._v(_vm._s(_vm.localLikes))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.likedTooltip))])]),_c('span',{staticClass:"mr-1"},[_vm._v("·")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(!_vm.isAuthenticated)?_c('v-icon',{staticClass:"mr-1",on:{"click":function($event){return _vm.onRead(_vm.work.id)}}},[_vm._v(" mdi-flag-outline ")]):(_vm.isAuthenticated && !_vm.localRead)?_c('v-icon',{staticClass:"mr-1",on:{"click":function($event){return _vm.onRead(_vm.work.id)}}},[_vm._v(" mdi-flag-outline ")]):_c('v-icon',{staticClass:"mr-1",on:{"click":function($event){return _vm.onRead(_vm.work.id)}}},[_vm._v(" mdi-flag ")]),_c('span',{staticClass:"subheading mr-2"},[_vm._v(_vm._s(_vm.localReads))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.readTooltip))])]),_c('span',{staticClass:"mr-1"},[_vm._v("·")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-clock ")]),_c('span',{staticClass:"subheading"},[_vm._v(_vm._s(_vm.work.timeString()))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.work.amount))])]),_c('v-spacer'),_c('a',{attrs:{"href":_vm.work.link,"target":"_blank"}},[_vm._v(_vm._s(_vm.work.linkText))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }